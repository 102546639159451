import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: ${(props) => (props.isMobile ? "fit-content" : "auto")};
  .becomeresponder-child-div {
    width: auto;
    @media (min-width: 1478px) {
      width: auto;
    }
    @media (max-width: 1245px) {
      width: fit-content;
    }
  }
`;

export const LayoutPageContainer = styled.div`
  overflow-x: hidden !important ;
  position: ${(props) => (props.isModalOpen ? "fixed" : "unset")};
`;
