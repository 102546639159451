import React, { useRef } from "react";
import { ModalContent, ModalHeader, ModalWrapper } from "./style";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useIntl } from "react-intl";

const MyModal = ({
  children,
  headerTitle = "",
  titleSize = "h4",
  className = "",
  top,
  height,
  width,
  closeModal = () => {},
  areYouSureForm = false,
  ...props
}) => {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => {
    closeModal();
  });

  return (
    <ModalWrapper className={`modal ${className}`}>
      <ModalContent
        className="content"
        top={top}
        height={height}
        width={width}
        areYouSureForm={areYouSureForm}
        ref={modalRef}
        {...props}
      >
        {headerTitle && (
          <ModalHeader className="header">{headerTitle}</ModalHeader>
        )}
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default MyModal;
