import styled from "styled-components";

export const BecomeResponderContainer = styled.div`
  .responder-header-div {
    padding-top: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .inner-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 32px;
        @media (max-width: 1000px) {
          font-size: 40px;
          line-height: 42px;
        }
        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 42px;
        }
      }
      .sub-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 52px;
        text-align: center;
        letter-spacing: 0.024em;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .action-button {
        width: max-content;
        height: 62px;
        @media (max-width: 600px) {
          height: fit-content;
          width: 350px;
          padding: 5px 0;
        }
      }
      .app-store-icons {
        margin-top: 60px;
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          height: 40px;
          width: 135px;
          border-radius: 5px;
        }
      }
    }
  }
`;

export const HowDoesItWorkContainer = styled.div`
  padding: 100px 160px 120px 160px;
  background-color: #F5F8FF;

  .header-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #056dff;
    text-align: center;
    span {
      color: #14283c;
    }
    margin-bottom: 84px;
    @media (max-width: 450px) {
      margin-left: -67px;
      width: 200px;
    }
    @media (max-width: 436px) {
      margin-left: -40px;
      width: 200px;
    }
    @media (max-width: 392px) {
      margin-left: -60px;
      width: 200px;
    }
    @media (max-width: 382px) {
      margin-left: -65px;
      width: 200px;
    }
    @media (max-width: 372px) {
      margin-left: -70px;
      width: 200px;
    }
    @media (max-width: 352px) {
      margin-left: -77px;
      width: 200px;
    }
    @media (max-width: 342px) {
      margin-left: -80px;
      width: 200px;
    }
    @media (max-width: 332px) {
      margin-left: -83px;
      width: 200px;
    }
    @media (max-width: 322px) {
      margin-left: -86px;
      width: 200px;
    }
    @media (max-width: 312px) {
      margin-left: -92px;
      width: 200px;
    }
    @media (max-width: 300px) {
      margin-left: -92px;
      width: 200px;
    }
  }

  .item-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: wrap;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: start;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: start;
    }
    .item {
      img {
        height: 300px;
        width: 357px;
        margin-bottom: 36px;
      }
      .div-image {
        height: 300px;
        width: 357px;
        margin-bottom: 36px;
      }
      div {
        font-family: human_sans_400;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.004em;
        color: #375a87;
        b {
          font-family: human_sans_700;
          font-weight: 700;
        }
      }
    }
  }
`;
