import styled from "styled-components";

export const NavBarContainer = styled.div`
  background-color: ${(props) =>
    props.colorChange ? "rgba(0, 0, 0, 0.45)" : "transparent"};
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 20;
  padding: 23px 160px 23px 160px;
  width: -webkit-fill-available;
  height: ${(props) => (props.isNavBarOpen ? "auto" : "100px")};
  @media (max-width: 768px) {
    padding: ${(props) =>
      props.isNavBarOpen ? "23px 50px 23px 50px" : "23px 50px 0 50px"};
  }
  @media (max-width: 1000px) {
    padding: ${(props) =>
      props.isNavBarOpen ? "23px 50px 23px 50px" : "23px 50px 0 50px"};
  }
  .navbar-presubscribe-btn {
    width: fit-content;
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  .navbar-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${(props) => (props.isFrenchLangActive ? "45px" : "60px")};
    .navbar-presubscribe-btn {
      width: ${(props) => (props.isFrenchLangActive ? "300px" : "fit-content")};
    }
    a {
      position: relative;
      font-family: roboto_condensed;
      font-style: normal;
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
      // text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #ffffff;
      text-decoration: none;
      opacity: 0.7;
      text-align: start;
      &.active {
        font-weight: 700;
        opacity: 1 !important;
        &:after {
          position: absolute;
          content: "";
          border-bottom: 2px solid #ebf0ff;
          width: -webkit-fill-available;
          left: 0;
          bottom: -9px;
        }
      }
    }
    .navbar-lang-div {
      .navbar-lang-eng {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
      .navbar-lang {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.7;
        cursor: pointer;
        &.active {
          font-weight: 600;
          text-decoration-line: underline;
          opacity: 1 !important;
        }
      }
    }
    .nav-lang-dropdown {
      position: relative;
      width: ${(props) => (props.isFrenchLangActive ? "180px" : "115px")};
      height: 48px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .nav-lang-country-name {
        font-family: roboto_condensed_700;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
      .dropdown-list {
        position: absolute;
        top: 51px;
        width: ${(props) =>
          props.isFrenchLangActive ? "-webkit-fill-available" : "116px"};
        height: auto;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        cursor: pointer;
        @media (min-width: 1000px) and (max-width: 1462px) {
          height: auto;
          width: ${(props) =>
            props.isFrenchLangActive ? "115px" : "inherit"};
        }
        .menu-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .menu-list-text {
            font-family: roboto_condensed_400;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #14283c;
          }
        }
      }
    }
  }
`;

export const NavBarForResponsiveContainer = styled.nav`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 20px;
  .navbar-right {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 30px;
    a {
      position: relative;
      font-family: roboto_condensed;
      font-style: normal;
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
      // text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #ffffff;
      text-decoration: none;
      text-align: start;
      opacity: 0.7;
      &.active {
        font-weight: 700;
        opacity: 1 !important;
        &:after {
          position: absolute;
          content: "";
          border-bottom: 2px solid #ebf0ff;
          width: -webkit-fill-available;
          left: 0;
          bottom: -9px;
        }
      }
    }
    .navbar-lang-div {
      .navbar-lang-eng {
        font-family: roboto_condensed;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
      .navbar-lang {
        font-family: roboto_condensed;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.7;
        cursor: pointer;
        &.active {
          font-weight: 600;
          text-decoration-line: underline;
          opacity: 1 !important;
        }
      }
    }
    .nav-lang-dropdown {
      position: relative;
      width: ${(props) => (props.isFrenchLangActive ? "180px" : "115px")};
      height: 48px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .nav-lang-country-name {
        font-family: roboto_condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
      .dropdown-list {
        position: absolute;
        top: 51px;
        width: ${(props) =>
          props.isFrenchLangActive ? "-webkit-fill-available" : "116px"};
        height: 96px;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        cursor: pointer;
        @media (min-width: 1000px) and (max-width: 1462px) {
          height: auto;
          width: inherit;
        }
        .menu-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .menu-list-text {
            font-family: roboto_condensed;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #14283c;
          }
        }
      }
    }
  }
`;
