import React, { useContext, useEffect } from "react";
import {
  PresSubscribeFormContainer,
  StyledPhoneInput,
  GenderListContainer,
} from "./style";
import MyInput from "../Elements/MyInput";
import { MyAppContext } from "../../context/MyAppContext";
import { useIntl } from "react-intl";
import "react-phone-number-input/style.css";
import { parsePhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import MyDatePicker from "../Elements/MyDatePicker";
import { MisterImage, MadamImage, discountImage } from "../../assests";
import MyCancelButton from "../Elements/MyCancelButton";
import MyButton from "../Elements/MyButton/index";
import MyModal from "../Elements/MyModal";
import { BsPatchCheckFill } from "react-icons/bs";
import moment from "moment";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const PreSubscribeForm = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    zipCode,
    setZipCode,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    closePreSubscribeModal,
    setNotification,
    setIsPreSubscribeModalOpen,
    countryCode,
    setCountryCode,
  } = useContext(MyAppContext);
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });
  const isResponsive = useMediaQuery({ query: `(max-width: 439px)` });

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setZipCode("");
    setDateOfBirth("");
    setGender("");
    setCountryCode("");
  }, []);

  const genderList = [
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.MALE",
      }),
      id: 1,
    },
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.FEMALE",
      }),
      id: 2,
    },
  ];

  const disableButton = () => {
    let emailCheck =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = emailCheck.test(email);
    if (
      firstName === "" ||
      // lastName === "" ||
      phone === "" ||
      zipCode === "" ||
      gender === ""
    ) {
      return true;
    } else {
      if (isValid) {
        return false;
      } else {
        return true;
      }
    }
  };

  const preSubscribeHandler = async () => {
    let postData = {
      first_name: firstName,
      // last_name: lastName,
      email: email,
      phone_number: formatPhoneNumber(phone),
      zip: zipCode,
      dob: `${moment(dateOfBirth).format("yyyy-MM-DD hh:mm:ss")}`,
      sex: gender.id === 1 ? "MALE" : "FEMALE",
      country_code: `+${countryCode}`,
    };
    if (lastName) {
      postData.last_name = lastName;
    }
    // console.log("postData", postData);
    // const formData = new FormData();
    // formData.append("first_name", firstName);
    // formData.append("last_name", lastName);
    // formData.append("email", email);
    // formData.append("phone_number", phone);
    // formData.append("zip", zipCode);
    // formData.append(
    //   "dob",
    //   `${moment(dateOfBirth).format("yyyy-MM-DD hh:mm:ss")}`
    // );
    // formData.append("sex", gender.id == 1 ? "MALE" : "FEMALE");

    // try {
    //   const response = await axios.post(
    //     `https://apiv1.weprotect-you.com/api/v1/contact/pre-subscribe`,
    //     postData
    //   );

    // } catch (err) {}

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/contact/pre-subscribe`,
        postData
      )
      .then((res) => {
        if (res.status === 201) {
          setNotification({
            message: intl.formatMessage({
              id: "MODAL.PRE_SUBSCRIPTION.NOTIFICATION",
            }),
            table: ``,
            backGroundColor: `#34CD77`,
            icon: <BsPatchCheckFill color="white" size={20} />,
          });
          setIsPreSubscribeModalOpen(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setZipCode("");
          setDateOfBirth("");
          setGender("");
          setCountryCode("");
        } else {
        }
      })
      .catch(() => {});
  };

  return (
    <MyModal
      closeModal={() => {
        closePreSubscribeModal();
      }}
      headerTitle={intl.formatMessage({
        id: "MODAL.PRE_SUBSCRIBE",
      })}
      height="auto"
      width={isMobile || isTablet ? (isResponsive ? "300px" : "auto") : "752px"}
    >
      <PresSubscribeFormContainer>
        <div className="d-flex">
          <MyInput
            label={intl.formatMessage({
              id: "MODAL.INPUT.FIRST_NAME",
            })}
            value={firstName}
            className="input-item"
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^A-Za-z]/gi, "");
              setFirstName(value);
            }}
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.FIRST_NAME.PLACEHOLER",
            })}
            //   required={true}
          />
          <MyInput
            label={intl.formatMessage({
              id: "MODAL.INPUT.LAST_NAME",
            })}
            value={lastName}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^A-Za-z]/gi, "");
              setLastName(value);
            }}
            className="input-item"
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.LAST_NAME.PLACEHOLER",
            })}
            optional={true}
          />
        </div>
        <div className="d-flex">
          <MyInput
            label={intl.formatMessage({
              id: "MODAL.INPUT.EMAIL",
            })}
            type="email"
            value={email}
            className="input-item"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.EMAIL.PLACEHOLER",
            })}
          />
          <div className="phone-number-div">
            <label
              style={{
                color: phone !== "" ? "#5573A0" : "#5573A0",
              }}
            >
              {intl.formatMessage({
                id: "MODAL.INPUT.PHONE_NUMBER",
              })}
            </label>
            <StyledPhoneInput
              placeholder={intl.formatMessage({
                id: "MODAL.INPUT.PHONE_NUMBER.PLACEHOLER",
              })}
              international
              value={phone}
              defaultCountry="FR"
              onChange={(value) => {
                if (value) {
                  let parsePhoneNumberValue = parsePhoneNumber(value);
                  setCountryCode(parsePhoneNumberValue?.countryCallingCode);
                }
                let phoneNumber = formatPhoneNumber(value);
                if (phoneNumber.length > 0 && phoneNumber.length <= 9) {
                  setPhone(value);
                } else {
                  setPhone("");
                }
              }}
              countryCallingCodeEditable={false}
            />
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            alignItems: isMobile || isTablet ? "start" : "",
          }}
        >
          <div
            className="d-flex"
            style={{
              gap: "6px",
              alignItems: isMobile || isTablet ? "start" : "",
              width: isMobile || isTablet ? "-webkit-fill-available" : "",
            }}
          >
            <MyDatePicker
              label={intl.formatMessage({
                id: "MODAL.INPUT.DATE_OF_BIRTH",
              })}
              value={dateOfBirth}
              className="input-item"
              onChange={(date) => {
                setDateOfBirth(date);
              }}
              width={isMobile || isTablet ? "-webkit-fill-available" : "169px"}
              placeholder={intl.formatMessage({
                id: "MODAL.INPUT.DATE_OF_BIRTH.PLACEHOLER",
              })}
            />
            <MyInput
              label={intl.formatMessage({
                id: "MODAL.INPUT.ZIP_CODE",
              })}
              value={zipCode}
              className="input-item"
              onChange={(e) => {
                if (e.target.value === "" || e.target.value.length <= 9) {
                  setZipCode(e.target.value);
                }
              }}
              width={isMobile || isTablet ? "-webkit-fill-available" : "169px"}
              placeholder={intl.formatMessage({
                id: "MODAL.INPUT.ZIP_CODE.PLACEHOLER",
              })}
            />
          </div>
          <div className="d-flex-column">
            <label
              style={{
                color: gender !== "" ? "#5573A0" : "#5573A0",
              }}
            >
              {intl.formatMessage({
                id: "MODAL.INPUT.GENDER",
              })}
            </label>
            <GenderListContainer>
              {genderList.map((genderEl, index) => {
                return (
                  <div
                    className="gender-div"
                    style={{
                      // opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                      backgroundColor:
                        genderEl?.id === gender?.id
                          ? "rgb(200 220 250 / 51%)"
                          : "rgb(200 220 250 / 18%)",
                      color:
                        genderEl?.id === gender?.id ? "#056DFF" : "#5573A0",
                    }}
                    onClick={() => {
                      setGender(genderEl);
                    }}
                    key={index}
                  >
                    <img
                      src={genderEl?.id === 1 ? MisterImage : MadamImage}
                      style={{
                        filter:
                          genderEl?.id === 2
                            ? "invert(54%) sepia(41%) saturate(4923%) hue-rotate(179deg) brightness(88%) contrast(101%)"
                            : "",
                        opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                      }}
                      alt=""
                    />
                    {genderEl?.id === gender?.id ? (
                      <b>{genderEl.name}</b>
                    ) : (
                      <span>{genderEl.name}</span>
                    )}
                  </div>
                );
              })}
            </GenderListContainer>
          </div>
        </div>
        <div className="subscription">
          <img src={discountImage} alt="" />
          <div
            className="subscription-info"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "MODAL.SUBSCRIPTION",
              }),
            }}
          />
        </div>
        <div className="action-buttons">
          <MyCancelButton
            onClick={() => {
              closePreSubscribeModal();
            }}
            className="cancel-btn"
          >
            <b>
              {intl.formatMessage({
                id: "BUTTON.CANCEL",
              })}
            </b>
          </MyCancelButton>
          <MyButton
            onClick={() => {
              preSubscribeHandler();
            }}
            className="submit-btn"
            disabled={disableButton()}
          >
            <b>
              {intl.formatMessage({
                id: "MODAL.PRE_SUBSCRIPTION.SUBMIT_BTN",
              })}
            </b>
          </MyButton>
        </div>
      </PresSubscribeFormContainer>
    </MyModal>
  );
};

export default PreSubscribeForm;
