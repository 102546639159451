import React, { useContext } from "react";
import {
  AboutUsContainer,
  HowItWorksContainer,
  ProtectionPlanContainer,
  ProjectRemoteContainer,
  WorkRemoteContainer,
  JobsContainer,
  YourPreferencesContainer,
} from "./style";
import {
  KeepingSafeImage,
  GooglePlayIcon,
  AppStoreIcon,
  HowItWorksSectionImage,
  discountImage,
  JobsSection,
  YourPreferences,
  BadgeCheck,
  WeProtectImage,
} from "../../assests";
import MyButton from "../Elements/MyButton";
import { FormattedMessage, useIntl } from "react-intl";
import { MyAppContext } from "../../context/MyAppContext";
import { useLang } from "../../i18n";
import { useMediaQuery } from "react-responsive";

const AboutUsPage = () => {
  const intl = useIntl();
  const {
    openPreSubscribeModal,
    setIsBecomeResponderModalOpen,
    userLoginGeoLocation,
  } = useContext(MyAppContext);
  const lang = useLang();
  const isMobile = useMediaQuery({ query: `(max-width: 375px)` });

  return (
    <AboutUsContainer
      isFrenchLangActive={lang === "fr" ? true : false}
      isMobile={isMobile}
    >
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(5, 109, 255, 0.12), rgba(5, 109, 255, 0.12)), linear-gradient(186.05deg, rgba(70, 86, 149, 0.5) 4.79%, rgba(0, 79, 191, 0.5) 95.21%),url(${KeepingSafeImage})`,
          height: "745px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="keeping-safe-container"
      >
        <div className="inner-container">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "ABOUT_US.KEEPING_YOU_SAFE",
              }),
            }}
          />
          <div
            className="sub-title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id:
                  userLoginGeoLocation?.currency === "USD"
                    ? "ABOUT_US.ORDER_PROTECTION_DOLLAR"
                    : "ABOUT_US.ORDER_PROTECTION",
              }),
            }}
          />

          <MyButton
            className="action-button"
            onClick={() => {
              openPreSubscribeModal();
            }}
          >
            <FormattedMessage id="BUTTON.ABOUT_US.PRE_SUBSCRIBE" />
          </MyButton>
          <div className="app-store-icons">
            <img src={GooglePlayIcon} alt="" />
            <img src={AppStoreIcon} alt="" />
          </div>
        </div>
      </div>
      <WorkRemoteContainer>
        <YourPreferencesContainer listImage={BadgeCheck}>
          <div className="wroking-image">
            <img src={YourPreferences} alt="" />
          </div>
          <div className="working-points">
            <div
              className="header-title"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUT_US.YOUR_PREFERENCES" }),
              }}
            />
            <ul style={{ paddingLeft: "24px" }}>
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.YOUR_PREFERENCES.POINT_ONE",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.YOUR_PREFERENCES.POINT_TWO",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.YOUR_PREFERENCES.POINT_THREE",
                  }),
                }}
              />
            </ul>
            <MyButton
              className="your-preference-action-button"
              onClick={() => {
                openPreSubscribeModal();
              }}
            >
              {intl.formatMessage({
                id: "BUTTON.ABOUT_US.PRE_SUBSCRIBE",
              })}
            </MyButton>
          </div>
        </YourPreferencesContainer>
        <HowItWorksContainer listImage={BadgeCheck}>
          <div className="working-points">
            <div
              className="header-title"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUT_US.HOW_IT_WORKS" }),
              }}
            />
            <ul style={{ paddingLeft: "24px" }}>
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.HOW_IT_WORKS.POINT_ONE",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.HOW_IT_WORKS.POINT_TWO",
                  }),
                }}
              />
            </ul>
            <MyButton
              className="how-works-action-button"
              onClick={() => {
                setIsBecomeResponderModalOpen(true);
              }}
              type="black"
            >
              {intl.formatMessage({
                id: "BUTTON.BECOME_RESPONDER",
              })}
            </MyButton>
          </div>
          <div className="wroking-image">
            <img src={HowItWorksSectionImage} alt="" />
          </div>
        </HowItWorksContainer>
        <ProjectRemoteContainer listImage={BadgeCheck}>
          <div className="image-div">
            <img src={WeProtectImage} alt="" />
          </div>
          <div className="working-points">
            <div
              className="header-title"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "ABOUT_US.WE_PROTECT_REMOTE",
                }),
              }}
            />

            <ul style={{ paddingLeft: "24px" }}>
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.WE_PROTECT_REMOTE.POINT_ONE",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.WE_PROTECT_REMOTE.POINT_THREE",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.WE_PROTECT_REMOTE.POINT_TWO",
                  }),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ABOUT_US.WE_PROTECT_REMOTE.POINT_FOUR",
                  }),
                }}
              />
            </ul>
            <MyButton
              className="action-button"
              onClick={() => {
                openPreSubscribeModal();
              }}
            >
              {intl.formatMessage({
                id: "BUTTON.ABOUT_US.PRE_SUBSCRIBE",
              })}
            </MyButton>
          </div>
        </ProjectRemoteContainer>
      </WorkRemoteContainer>
      <ProtectionPlanContainer>
        <div className="inner-div">
          <div
            className="div-header"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "ABOUT_US.PROTECTION_PLAN",
              }),
            }}
          />
          <div
            className="div-plans"
            style={{
              alignItems: "flex-start",
            }}
          >
            <div
              className="plan"
              style={{
                height: lang === "fr" ? "560px" : "462px",
              }}
            >
              <div>
                <div className="plan-header">
                  <div
                    className="header-left"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_ONE_HEADER_LEFT",
                      }),
                    }}
                  />
                  <div
                    className="header-right"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id:
                          userLoginGeoLocation?.currency === "USD"
                            ? "ABOUT_US.PROTECTION_PLAN.CARD_ONE_HEADER_RIGHT_USD"
                            : "ABOUT_US.PROTECTION_PLAN.CARD_ONE_HEADER_RIGHT",
                      }),
                    }}
                  />
                </div>
                <div className="plan-list">
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />
                    {intl.formatMessage({
                      id: "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_ONE",
                    })}
                  </div>
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            userLoginGeoLocation?.currency === "USD"
                              ? "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_TWO_USD"
                              : "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_TWO",
                        }),
                      }}
                    />
                  </div>
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            userLoginGeoLocation?.currency === "USD"
                              ? "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_THREE_USD"
                              : "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_THREE",
                        }),
                      }}
                    />
                  </div>
                  <div
                    className="plan-star"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_ONE.POINT_FOUR",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="subscription-action">
                {/* <div className="subscription-div">
                  <img src={discountImage} alt="" />
                  <div
                    className="subscription-info"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_ONE.SUBSCRIPTION",
                      }),
                    }}
                  />
                </div> */}
                <MyButton
                  className="subscription-action-button"
                  onClick={() => {
                    openPreSubscribeModal();
                  }}
                >
                  {intl.formatMessage({
                    id: "BUTTON.ABOUT_US.PRE_SUBSCRIBE",
                  })}
                </MyButton>
              </div>
            </div>
            <div
              className="plan"
              style={{
                height: lang === "fr" ? "560px" : "462px",
              }}
            >
              <div>
                <div className="plan-header">
                  <div
                    className="header-left"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_TWO_HEADER_LEFT",
                      }),
                    }}
                  />
                  <div
                    className="header-right"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id:
                          userLoginGeoLocation?.currency === "USD"
                            ? "ABOUT_US.PROTECTION_PLAN.CARD_TWO_HEADER_RIGHT_USD"
                            : "ABOUT_US.PROTECTION_PLAN.CARD_TWO_HEADER_RIGHT",
                      }),
                    }}
                  />
                </div>
                <div className="plan-list">
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />
                    {intl.formatMessage({
                      id: "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_ONE",
                    })}
                  </div>
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            userLoginGeoLocation?.currency === "USD"
                              ? "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_TWO_USD"
                              : "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_TWO",
                        }),
                      }}
                    />
                  </div>
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id:
                            userLoginGeoLocation?.currency === "USD"
                              ? "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_THREE_USD"
                              : "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_THREE",
                        }),
                      }}
                    />
                  </div>
                  <div className="plan-list-item">
                    <i className="fas fa-circle" />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_FOUR",
                        }),
                      }}
                    />
                  </div>
                  <div
                    className="plan-star"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_TWO.POINT_FIVE",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="subscription-action">
                {/* <div className="subscription-div">
                  <img src={discountImage} alt="" />
                  <div
                    className="subscription-info"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "ABOUT_US.PROTECTION_PLAN.CARD_TWO.SUBSCRIPTION",
                      }),
                    }}
                  />
                </div> */}
                <MyButton
                  className="subscription-action-button"
                  onClick={() => {
                    openPreSubscribeModal();
                  }}
                >
                  {intl.formatMessage({
                    id: "BUTTON.ABOUT_US.PRE_SUBSCRIBE",
                  })}
                </MyButton>
              </div>
            </div>
          </div>
        </div>
      </ProtectionPlanContainer>
      <JobsContainer image={JobsSection}>
        <div
          className="header-title"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "ABOUT_US.JOBS",
            }),
          }}
        />
        <div
          className="header-subtitle1"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "ABOUT_US.JOBS.SUBTITLE_ONE",
            }),
          }}
        />
        <div
          className="header-subtitle2"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "ABOUT_US.JOBS.SUBTITLE_TWO",
            }),
          }}
        />
        <MyButton
          className="jobs-action-button"
          onClick={() => setIsBecomeResponderModalOpen(true)}
        >
          {intl.formatMessage({
            id: "BUTTON.BECOME_RESPONDER",
          })}
        </MyButton>
      </JobsContainer>
    </AboutUsContainer>
  );
};

export default AboutUsPage;
