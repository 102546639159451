import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(5px);
  z-index: 100 !important;
  display: block;
`;

export const ModalContent = styled.div`
  height: ${(props) => (props?.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "50%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  position: absolute;
  left: 50%;
  top: ${(props) => (props.top ? props.top : "50%")};
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px 24px;
  overflow: ${(props) => props.overflow || "visible"};
  @media (max-width: 768px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    max-height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh")};
  }
  @media (max-width: 1000px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    max-height: ${(props) =>
      props?.areYouSureForm ? "auto" : "97vh !important"};
  }
  @media (max-width: 800px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    max-height: ${(props) =>
      props?.areYouSureForm ? "auto" : "97vh !important"};
  }
  @media (max-width: 900px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    max-height: ${(props) =>
      props?.areYouSureForm ? "auto" : "97vh !important"};
  }
  @media (max-height: 705px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    max-height: ${(props) =>
      props?.areYouSureForm ? "auto" : "97vh !important"};
  }
  @media (max-height: 665px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
  @media (max-height: 600px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
  @media (max-height: 500px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
  @media (max-height: 558px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
  @media (max-height: 400px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
  @media (max-height: 300px) {
    overflow-y: ${(props) => (props?.areYouSureForm ? "hidden" : "auto")};
    height: ${(props) => (props?.areYouSureForm ? "auto" : "97vh !important")};
  }
`;

export const ModalHeader = styled.div`
  font-family: "roboto_condensed_700";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #14283c;
`;

export const ModalFooter = styled.div`
  position: ${(props) => props?.position || "sticky"};
  margin: 20px 0 0 -24px;
  background: #fff;
  bottom: 0;
  left: 0;
  height: auto;
  padding: 24px 24px 0;
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
  gap: 20px;
  width: calc(100% + 48px);
  border-top: 1px solid #e7e7e7;

  button:not(.remove) {
    height: 40px;
    min-width: 112px;
    width: fit-content;
  }
`;
