import React, { useContext, useEffect, useState, useRef } from "react";
import {
  BecomeResponderFormContainer,
  StyledPhoneInput,
  GenderListContainer,
  TransportationDropDownContainer,
  StyledCountryDropDown,
  StyledStateDropDown,
  StyledStateDropDownContainer,
} from "./style";
import MyInput from "../Elements/MyInput";
import { MyAppContext } from "../../context/MyAppContext";
import { useIntl } from "react-intl";
import "react-phone-number-input/style.css";
import { parsePhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import MyDatePicker from "../Elements/MyDatePicker";
import { MisterImage, MadamImage } from "../../assests";
import MyCancelButton from "../Elements/MyCancelButton";
import MyButton from "../Elements/MyButton/index";
import MyModal from "../Elements/MyModal";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BsPatchCheckFill } from "react-icons/bs";
import MyFileUploader from "../Elements/MyFileUploader";
import useOutsideClick from "../../hooks/useOutsideClick";
import moment from "moment";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const BecomeResponderForm = () => {
  const transportationDropdown = useRef();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    zipCode,
    setZipCode,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    setNotification,
    selectedTransactionType,
    setSelectedTransactionType,
    isTransportationTypeDropDownOpen,
    setIsTransportationTypeDropDownOpen,
    address,
    setAddress,
    city,
    setCity,

    country,
    setCountry,
    state,
    setState,
    setIsBecomeResponderModalOpen,

    passportId,
    setPassportId,
    selectedPassportFile,
    setSelectedPassportFile,

    ssn,
    setSSN,
    selectedSSNFile,
    setSelectedSSnFile,

    document,
    setDocument,
    selectedDocumentFile,
    setSelectedDocumentFile,

    setCancelBecomeResponderForm,
    countryCode,
    setCountryCode,
  } = useContext(MyAppContext);
  const intl = useIntl();

  const [current, setCurrent] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });
  const isResponsive = useMediaQuery({ query: `(max-width: 439px)` });

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setZipCode("");
    setDateOfBirth("");
    setGender("");
    setSelectedTransactionType("");
    setIsTransportationTypeDropDownOpen(false);
    setAddress("");
    setCity("");
    setCountry("France");
    setState("");

    setPassportId("");
    setSSN("");
    setDocument("");
    setSelectedPassportFile("");
    setSelectedSSnFile("");
    setSelectedDocumentFile("");
    setCountryCode("");
  }, []);

  const genderList = [
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.MALE",
      }),
      id: 1,
    },
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.FEMALE",
      }),
      id: 2,
    },
  ];

  const disableButton = () => {
    let emailCheck =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = emailCheck.test(email);
    if (
      firstName === "" ||
      phone === "" ||
      zipCode === "" ||
      gender === "" ||
      selectedTransactionType === "" ||
      address === "" ||
      city === "" ||
      country === "" ||
      state === ""
    ) {
      return true;
    } else {
      if (isValid) {
        return false;
      } else {
        return true;
      }
    }
  };

  const transportaionTypesList = ["Foot", "Motocycle", "Bicycle", "Car"];

  const disableBecomeResponderButton = () => {
    if (
      passportId === "" ||
      selectedPassportFile === "" ||
      ssn === "" ||
      selectedSSNFile === "" ||
      document === "" ||
      selectedDocumentFile === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const becomeResponderHandler = () => {
    // let postData = {
    //   // assetFile: selectedPassportFile,
    //   // assetFile: selectedSSNFile,
    //   // assetFile: selectedDocumentFile,
    //   assetFile: [selectedPassportFile, selectedSSNFile, selectedDocumentFile],
    //   first_name: firstName,
    //   last_name: lastName,
    //   email: email,
    //   // phone_number: phone,
    //   phone_number: 1234567890,
    //   // zip: zipCode,
    //   zip: 45588,
    //   dob: `${moment(dateOfBirth).format("yyyy-MM-DD hh:mm:ss")}`,
    //   sex: gender.id == 1 ? "MALE" : "FEMALE",
    //   transport_type: selectedTransactionType,
    //   address: address,
    //   city: city,
    //   country: country,
    //   state: state,
    //   passport_id: passportId,
    //   ssn: ssn,
    //   background_document: document,
    // };
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("assetFile", [
      [selectedPassportFile],
      [selectedSSNFile],
      [selectedDocumentFile],
    ]);
    formData.append("last_name", lastName);
    formData.append("email", email);
    // formData.append("phone_number", 1234567890);
    formData.append("phone_number", formatPhoneNumber(phone));
    formData.append("country_code", `+${countryCode}`);
    formData.append("zip", 45588);
    formData.append("dob", moment(dateOfBirth).format("yyyy-MM-DD hh:mm:ss"));
    formData.append("sex", gender.id === 1 ? "MALE" : "FEMALE");
    formData.append("transport_type", selectedTransactionType);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("passport_id", passportId);
    formData.append("ssn", ssn);
    formData.append("background_document", document);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/contact/responder`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          setNotification({
            message: intl.formatMessage({
              id: "MODAL.BECOME_RESPONDER.NOTIFICATION",
            }),
            table: ``,
            backGroundColor: `#34CD77`,
            icon: <BsPatchCheckFill color="white" size={20} />,
          });
          setIsBecomeResponderModalOpen(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setZipCode("");
          setDateOfBirth("");
          setGender("");
          setSelectedTransactionType("");
          setIsTransportationTypeDropDownOpen(false);
          setAddress("");
          setCity("");
          setCountry("United States");
          setState("");

          setPassportId("");
          setSSN("");
          setDocument("");
          setSelectedPassportFile("");
          setSelectedSSnFile("");
          setSelectedDocumentFile("");
          setCountryCode("");
        } else {
        }
      });
  };

  useOutsideClick(transportationDropdown, () => {
    setIsTransportationTypeDropDownOpen(false);
  });
  return (
    <MyModal
      closeModal={() => {
        setCancelBecomeResponderForm(true);
      }}
      headerTitle={intl.formatMessage({
        id: "MODAL.BECOME_RESPONDER",
      })}
      height={"auto"}
      width={isMobile || isTablet ? (isResponsive ? "300px" : "auto") : "752px"}
    >
      <BecomeResponderFormContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "19px",
          }}
        >
          <div
            style={{
              fontFamily: "human_sans_700",
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#056DFF",
              color: "#ffffff",
              width: "20px",
              height: "20px",
              borderRadius: "32px",
              border: "1px solid transparent",
              paddingRight: "2px",
              paddingTop: "3px",
            }}
          >
            1
          </div>
          <div
            style={{
              height: "2px",
              width: "250px",
              backgroundColor: current !== 1 ? "#c8dcfa" : "#056DFF",
            }}
            className="progress-connector-line"
          ></div>
          <div
            style={{
              fontFamily: "human_sans_700",
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: current !== 1 ? "#c8dcfa" : "#056DFF",
              color: current !== 1 ? "#056DFF" : "#ffffff",
              width: "20px",
              height: "20px",
              borderRadius: "32px",
              border: "1px solid transparent",
              paddingRight: "1px",
              paddingTop: "3px",
            }}
          >
            2
          </div>
        </div>
        {current === 0 ? (
          <>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.FIRST_NAME",
                })}
                value={firstName}
                className="input-item"
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z]/gi, "");
                  setFirstName(value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.FIRST_NAME.PLACEHOLER",
                })}
                //   required={true}
              />
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.LAST_NAME",
                })}
                value={lastName}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z]/gi, "");
                  setLastName(value);
                }}
                className="input-item"
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.LAST_NAME.PLACEHOLER",
                })}
                optional={true}
              />
            </div>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.EMAIL",
                })}
                type="email"
                value={email}
                className="input-item"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.EMAIL.PLACEHOLER",
                })}
              />
              <div className="phone-number-div">
                <label
                  style={{
                    color: phone !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.PHONE_NUMBER",
                  })}
                </label>
                <StyledPhoneInput
                  placeholder={intl.formatMessage({
                    id: "MODAL.INPUT.PHONE_NUMBER.PLACEHOLER",
                  })}
                  international
                  value={phone}
                  defaultCountry="FR"
                  onChange={(value) => {
                    if (value) {
                      let parsePhoneNumberValue = parsePhoneNumber(value);
                      setCountryCode(parsePhoneNumberValue?.countryCallingCode);
                    }
                    let phoneNumber = formatPhoneNumber(value);
                    if (phoneNumber.length > 0 && phoneNumber.length <= 9) {
                      setPhone(value);
                    } else {
                      setPhone("");
                    }
                  }}
                  countryCallingCodeEditable={false}
                />
              </div>
            </div>
            <div className="d-flex">
              <MyDatePicker
                label={intl.formatMessage({
                  id: "MODAL.INPUT.DATE_OF_BIRTH",
                })}
                value={dateOfBirth}
                className="input-item"
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
                width={
                  isMobile || isTablet
                    ? "-webkit-fill-available"
                    : "-webkit-fill-available"
                }
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.DATE_OF_BIRTH.PLACEHOLER",
                })}
              />
              <div className="d-flex-column">
                <label
                  style={{
                    color: gender !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.GENDER",
                  })}
                </label>
                <GenderListContainer>
                  {genderList.map((genderEl, index) => {
                    return (
                      <div
                        className="gender-div"
                        style={{
                          // opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                          backgroundColor:
                            genderEl?.id === gender?.id
                              ? "rgb(200 220 250 / 51%)"
                              : "rgb(200 220 250 / 18%)",
                          color:
                            genderEl?.id === gender?.id ? "#056dff" : "#5573A0",
                        }}
                        onClick={() => {
                          setGender(genderEl);
                        }}
                        key={index}
                      >
                        <img
                          src={genderEl?.id === 1 ? MisterImage : MadamImage}
                          style={{
                            filter:
                              genderEl?.id === 2
                                ? "invert(54%) sepia(41%) saturate(4923%) hue-rotate(179deg) brightness(88%) contrast(101%)"
                                : "",
                            opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                            visibility:
                              isTablet && isTransportationTypeDropDownOpen
                                ? "hidden"
                                : "visible",
                          }}
                          alt=""
                        />
                        {genderEl?.id === gender?.id ? (
                          <b>{genderEl.name}</b>
                        ) : (
                          <span>{genderEl.name}</span>
                        )}
                      </div>
                    );
                  })}
                </GenderListContainer>
              </div>
            </div>
            <div className="d-flex">
              <TransportationDropDownContainer ref={transportationDropdown}>
                <label
                  style={{
                    color:
                      selectedTransactionType !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.TRANSPORTATION_DROPDOWN",
                  })}
                </label>
                <div className="select-transportation">
                  {selectedTransactionType !== "" ? (
                    <span
                      className="select"
                      style={{
                        cursor: "pointer",
                        width: "-webkit-fill-available",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    >
                      {selectedTransactionType}
                    </span>
                  ) : (
                    <span
                      className="un-select"
                      style={{
                        cursor: "pointer",
                        width: "-webkit-fill-available",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    >
                      {intl.formatMessage({
                        id: "MODAL.INPUT.TRANSPORTATION_DROPDOWN.SELECT_TYPE",
                      })}
                    </span>
                  )}
                  {isTransportationTypeDropDownOpen ? (
                    <FiChevronUp
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    />
                  ) : (
                    <FiChevronDown
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    />
                  )}
                  {isTransportationTypeDropDownOpen ? (
                    <div className="dropdown-list">
                      {transportaionTypesList.map((el, i) => {
                        return (
                          <span
                            onClick={() => {
                              setSelectedTransactionType(el);
                              setIsTransportationTypeDropDownOpen(
                                !isTransportationTypeDropDownOpen
                              );
                            }}
                            style={{
                              width: "-webkit-fill-available",
                            }}
                            key={i}
                          >
                            {el}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </TransportationDropDownContainer>
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.ADDRESS",
                })}
                value={address}
                className="input-item"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.ADDRESS.PLACEHOLER",
                })}
                //   required={true}
              />
            </div>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.CITY",
                })}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                className="input-item"
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.CITY.PLACEHOLER",
                })}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  width: isMobile || isTablet ? "-webkit-fill-available" : "",
                }}
              >
                <label
                  style={{
                    color: country !== "" ? "#5573A0" : "#5573A0",
                    fontFamily: "human_sans_400",
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.COUNTRY",
                  })}
                </label>
                <StyledCountryDropDown
                  defaultOptionLabel="Select a country, man."
                  value={country}
                  onChange={(value) => {
                    setCountry(value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex">
              <StyledStateDropDownContainer>
                <label
                  style={{
                    color: state !== "" ? "#5573A0" : "#5573A0",
                    fontFamily: "human_sans_400",
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.STATE",
                  })}
                </label>
                <StyledStateDropDown
                  blankOptionLabel="No country selected, man."
                  defaultOptionLabel="Select State"
                  country={country}
                  value={state}
                  onChange={(value) => {
                    setState(value);
                  }}
                  labelType="short"
                />
              </StyledStateDropDownContainer>
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.ZIP_CODE",
                })}
                value={zipCode}
                className="input-item"
                onChange={(e) => {
                  if (e.target.value === "" || e.target.value.length <= 9) {
                    setZipCode(e.target.value);
                  }
                }}
                width={
                  isMobile || isTablet
                    ? "-webkit-fill-available"
                    : "-webkit-fill-available"
                }
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.ZIP_CODE.PLACEHOLER",
                })}
              />
            </div>
            <div className="action-buttons">
              <MyCancelButton
                onClick={() => {
                  setCancelBecomeResponderForm(true);
                }}
                className="cancel-btn"
              >
                <b>
                  {intl.formatMessage({
                    id: "BUTTON.CANCEL",
                  })}
                </b>
              </MyCancelButton>
              <MyButton
                onClick={() => {
                  setCurrent(1);
                }}
                className="submit-btn"
                disabled={disableButton()}
              >
                <b>
                  {intl.formatMessage({
                    id: "BUTTON.CONTINUE",
                  })}
                </b>
              </MyButton>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex"
              style={{
                alignItems: "end",
              }}
            >
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.PASSPORTID",
                })}
                value={passportId}
                className="input-item"
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setPassportId(e.target.value);
                  }
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.PASSPORTID.PLACEHOLER",
                })}
                //   required={true}
              />
              <MyFileUploader
                label={intl.formatMessage({
                  id: "MODAL.FILE.PASSSPORT_ID",
                })}
                value={selectedPassportFile}
                onChange={(file) => {
                  setSelectedPassportFile(file);
                }}
              />
            </div>
            <div
              className="d-flex"
              style={{
                alignItems: "end",
              }}
            >
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.SSN",
                })}
                value={ssn}
                className="input-item"
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setSSN(Number(e.target.value) || "");
                  }
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.SSN.PLACEHOLER",
                })}
                //   required={true}
              />
              <MyFileUploader
                label={intl.formatMessage({
                  id: "MODAL.FILE.SSN",
                })}
                value={selectedSSNFile}
                onChange={(file) => {
                  setSelectedSSnFile(file);
                }}
              />
            </div>
            <div
              className="d-flex"
              style={{
                alignItems: "end",
              }}
            >
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.DOCUMNET",
                })}
                value={document}
                className="input-item"
                onChange={(e) => {
                  if (
                    e.target.value.length >= 0 &&
                    e.target.value.length <= 11
                  ) {
                    setDocument(e.target.value);
                  }
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.DOCUMNET.PLACEHOLER",
                })}
                //   required={true}
              />
              <MyFileUploader
                label={intl.formatMessage({
                  id: "MODAL.FILE.DOUCMNET",
                })}
                value={selectedDocumentFile}
                onChange={(file) => {
                  setSelectedDocumentFile(file);
                }}
              />
            </div>
            <div className="action-buttons">
              <MyCancelButton
                onClick={() => {
                  setCurrent(0);
                }}
                className="cancel-btn"
              >
                <b>
                  {intl.formatMessage({
                    id: "BUTTON.BACK",
                  })}
                </b>
              </MyCancelButton>
              <MyButton
                onClick={() => {
                  becomeResponderHandler();
                }}
                className="submit-btn-form"
                disabled={disableBecomeResponderButton()}
              >
                <b>
                  {intl.formatMessage({
                    id: "BUTTON.BECOME_RESPONDER",
                  })}
                </b>
              </MyButton>
            </div>
          </>
        )}
      </BecomeResponderFormContainer>
    </MyModal>
  );
};

export default BecomeResponderForm;
