import React, { useContext } from "react";
import { CancelConfirmationModalContainer } from "./style";
import { MyAppContext } from "../../../context/MyAppContext";
import { useIntl } from "react-intl";
import MyButton from "../../Elements/MyButton/index";
import MyModal from "../../Elements/MyModal";
import MyCancelButton from "../../Elements/MyCancelButton";
import { useMediaQuery } from "react-responsive";

const CancelBecomeResponderConfirmationModal = () => {
  const { setCancelBecomeResponderForm, setIsBecomeResponderModalOpen } =
    useContext(MyAppContext);
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });

  return (
    <MyModal
      closeModal={() => {
        setCancelBecomeResponderForm(false);
      }}
      headerTitle={intl.formatMessage({
        id: "MODAL.ARE_YOU_SURE",
      })}
      height="auto"
      areYouSureForm={true}
      width={isMobile || isTablet ? "300px" : "364px"}
    >
      <CancelConfirmationModalContainer>
        <div
          // className="cancel-confiramtion-text"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "MODAL.BECOME_RESPONDER.CONFIRMATION_MESSAAGE",
            }),
          }}
          style={{ textAlign: "center" }}
        />
        <MyButton
          onClick={() => {
            setCancelBecomeResponderForm(false);
          }}
          className="continue-btn"
        >
          <b>
            {intl.formatMessage({
              id: "MODAL.CONFIRMATION.BUTTON.CONTINUE",
            })}
          </b>
        </MyButton>
        <MyCancelButton
          onClick={() => {
            setIsBecomeResponderModalOpen(false);
            setCancelBecomeResponderForm(false);
          }}
          className="close-btn"
        >
          <b>
            {intl.formatMessage({
              id: "MODAL.CONFIRMATION.BUTTON.CLOSE",
            })}
          </b>
        </MyCancelButton>
      </CancelConfirmationModalContainer>
    </MyModal>
  );
};

export default CancelBecomeResponderConfirmationModal;
