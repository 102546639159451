import React from "react";
import { MyCancelButtonContainer } from "./style";

export default function MyCancelButton({
  children,
  className = "",
  onClick = () => {},
  disabled = false,
}) {
  return (
    <MyCancelButtonContainer
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MyCancelButtonContainer>
  );
}
