import styled from "styled-components";

export const MyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
  gap: 2px;
  align-items: start;
  flex: 1 1 0;
  label {
    font-family: human_sans_400;
    font-style: normal;
    font-size: 11px;
    line-height: 12px;
    color: ${(props) => (props.value ? "#5573A0" : "#5573A0")};
  }
  .div-input {
    width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
    border-radius: 12px;
    // border: solid 1px #9b9b9b;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: start;
    }
    .optional-field {
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.002em;
      color: #91afd7;
      padding: 0 12px;
    }
  }
`;

// export const StyledInput = styled.input`
//   width: 100%;
//   background-color: #ffffff;
//   box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
//   border-radius: 12px;
//   height: 44px !important;
//   outline: unset;
// `;

export const StyledInput = styled.input`
  font-family: human_sans_400;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #14283C;
  outline: unset !important;
  border: unset !important;
  padding: 10px 12px;
  border-radius: 12px;
  width: -webkit-fill-available;
  :focus-visible {
    outline: unset !important;
  }
  ::placeholder {
    color: #91AFD7;
    font-family: "human_sans_400";
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;
