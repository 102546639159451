import React, { useContext } from "react";
import { BecomeResponderContainer, HowDoesItWorkContainer } from "./style";
import {
  JobsSection,
  GooglePlayIcon,
  AppStoreIcon,
  BecomeResponderImageOne,
  BecomeResponderImageTwo,
  BecomeResponderImageThree,
} from "../../assests";
import MyButton from "../Elements/MyButton";
import { useIntl } from "react-intl";
import { MyAppContext } from "../../context/MyAppContext";
import { useMediaQuery } from "react-responsive";

const BecomeResponderPage = () => {
  const intl = useIntl();
  const { setIsBecomeResponderModalOpen, userLoginGeoLocation } =
    useContext(MyAppContext);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });
  return (
    <BecomeResponderContainer>
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(5, 109, 255, 0.16), rgba(5, 109, 255, 0.16)), linear-gradient(186.05deg, rgba(70, 86, 149, 0.5) 4.79%, rgba(15, 27, 77, 0.5) 91.43%),url(${JobsSection})`,
          height: isMobile || isTablet ? "auto" : "516px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: isMobile || isTablet ? "20px" : "unset",
        }}
        className="responder-header-div "
      >
        <div className="inner-container">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id:
                  userLoginGeoLocation?.currency === "USD"
                    ? "BECOME_RESPONDER.TITLE_USD"
                    : "BECOME_RESPONDER.TITLE",
              }),
            }}
          />
          {/* <div
            className="sub-title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "BECOME_RESPONDER.SUB_TITLE" }),
            }}
          /> */}
          <MyButton
            className="action-button"
            onClick={() => setIsBecomeResponderModalOpen(true)}
          >
            {intl.formatMessage({ id: "BUTTON.BECOME_RESPONDER" })}
          </MyButton>
          <div className="app-store-icons">
            <img src={GooglePlayIcon} alt="" />
            <img src={AppStoreIcon} alt="" />
          </div>
        </div>
      </div>
      <HowDoesItWorkContainer>
        <div
          className="header-title"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "ABOUT_US.HOW_IT_WORKS" }),
          }}
        />
        <div className="item-flex">
          <div className="item">
            {/* <div
              style={{
                backgroundImage: `url(${BecomeResponderImageThree})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "276px",
                height: "276px",
              }}
              className="div-image"
            ></div> */}
            <img src={BecomeResponderImageThree} alt="" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "BECOME_RESPONDER.POINT_ONE",
                }),
              }}
            />
          </div>
          <div className="item">
            <img src={BecomeResponderImageTwo} alt="" />
            {/* <div
              style={{
                backgroundImage: `url(${BecomeResponderImageTwo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "276px",
                height: "276px",
              }}
              className="div-image"
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "BECOME_RESPONDER.POINT_TWO",
                }),
              }}
            />
          </div>
          <div className="item">
            <img src={BecomeResponderImageOne} alt="" />
            {/* <div
              style={{
                backgroundImage: `url(${BecomeResponderImageOne})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "276px",
                height: "276px",
              }}
              className="div-image"
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    userLoginGeoLocation?.currency === "USD"
                      ? "BECOME_RESPONDER.POINT_THREE_USD"
                      : "BECOME_RESPONDER.POINT_THREE",
                }),
              }}
            />
          </div>
        </div>
      </HowDoesItWorkContainer>
    </BecomeResponderContainer>
  );
};
export default BecomeResponderPage;
