import styled from "styled-components";

export const CancelConfirmationModalContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cancel-confiramtion-text {
    font-family: "human_sans_400";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.004em;
    color: #14283c;
    b {
      font-family: "human_sans_700";
    }
  }
  .continue-btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .close-btn {
    width: 100%;
  }
`;
