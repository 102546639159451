import React, { useRef, useState, useEffect } from "react";
import { MyFileUploadContainer, StyledProgressBar } from "./style";
import { FaPlus, FaFilePdf, FaFileImage } from "react-icons/fa";
import { useIntl } from "react-intl";
import { RiCloseFill } from "react-icons/ri";

const MyFileUploader = ({ label, value, onChange }) => {
  const intl = useIntl();
  const inputRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState("");

  const fileTypeRednder = (value) => {
    let fileType = value?.name?.split(".").pop();
    if (fileType === "pdf") {
      return <FaFilePdf size={20} style={{ color: "#056DFF" }} />;
    } else {
      return <FaFileImage size={20} style={{ color: "#056DFF" }} />;
    }
  };

  useEffect(() => {
    if (progress > 0 && progress < 100) {
      doSetTimeout();
    } else {
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    if (value) {
      setSelectedFile(value);
    }
  }, [value]);

  function doSetTimeout() {
    setTimeout(function () {
      let value = progress + 25;
      setProgress(value);
    }, 100);
  }

  // const onUpload = async (e) => {
  //   const formData = new FormData();
  //   formData.append("asset", e.target.files[0]);
  //   formData.append("type", "restaurant");
  //   formData.append("api_key", process.env.REACT_APP_API_KEY);
  //   customAxios
  //     .post(`${process.env.REACT_APP_API_URL}api/v1/asset/upload`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         onChange(res.data);
  //       } else {
  //       }
  //     });
  // };

  return (
    <MyFileUploadContainer progress={progress} isFileAdded={selectedFile}>
      {selectedFile !== "" || progress !== 0 ? (
        <div className="selected-file">
          <div className="file-type-name">
            <div>{fileTypeRednder(selectedFile)}</div>
            <div className="file-name">
              <span className="file-name-span">
                {selectedFile?.name}{" "}
                {progress !== 0 && (
                  <span>{(selectedFile?.size / 1024).toFixed(2)} Kb</span>
                )}
              </span>
              <span className="file-size-span">
                {progress === 0 ? (
                  `${(selectedFile?.size / 1024).toFixed(2)} Kb`
                ) : (
                  <StyledProgressBar percent={progress} />
                )}
              </span>
            </div>
          </div>
          {progress === 0 ? (
            <>
              {value && (
                <RiCloseFill
                  size={20}
                  style={{ color: "#5573A0", cursor: "pointer" }}
                  onClick={() => {
                    onChange("");
                    setSelectedFile("");
                  }}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div
          className="upload-file-info"
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <input
            type="file"
            id="file"
            ref={inputRef}
            accept="image/*,.pdf"
            style={{ display: "none" }}
            onChange={(e) => {
              let fileSize = e.target.files[0].size;
              if (fileSize <= 158000000) {
                onChange(e.target.files[0]);
                setProgress(25);
                setSelectedFile(e.target.files[0]);
                // onUpload(e);
              }
            }}
          />
          <span className="plus-span">
            <FaPlus /> {label}
          </span>
          <span className="info">
            {intl.formatMessage({
              id: "MODAL.FILE_SIZE",
            })}
          </span>
        </div>
      )}
    </MyFileUploadContainer>
  );
};

export default MyFileUploader;
