import React from "react";
import { MyInputContainer, StyledInput } from "./style";
import { useIntl } from "react-intl";

const MyInput = ({
  label = "",
  value = "",
  onChange = () => {},
  className = "",
  type = "text",
  width = "",
  placeholder = "",
  required = false,
  optional = false,
}) => {
  const intl = useIntl();

  return (
    <MyInputContainer width={width} value={value !== ""}>
      {label && (
        <label>
          {label}
          {required && "*"}
        </label>
      )}
      <div className="div-input">
        <StyledInput
          value={value}
          onChange={onChange}
          className={className}
          type={type}
          placeholder={placeholder}
        />
        {optional ? (
          <div className="optional-field">
            {intl.formatMessage({
              id: "MODAL.OPTIONAL_FIELD",
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </MyInputContainer>
  );
};

export default MyInput;
