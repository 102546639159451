import styled from "styled-components";
import { Progress } from "antd";

export const MyFileUploadContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  border-radius: 12px;
  // padding: ${(props) => (props.progress !== 100 ? "1px 6px" : "10px 12px")};
  padding: ${(props) =>
    props.isFileAdded
      ? props.progress !== 0
        ? "1px"
        : "6.5px 12px"
      : "10px 12px"};
  flex: 1 1 0;
  width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
  .upload-file-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: start;
    }
    .plus-span {
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ff2c2c;
      cursor: pointer;
      svg {
        margin-bottom: 3px;
      }
    }
    .info {
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 24px;
      text-align: right;
      letter-spacing: -0.2px;
      color: #5573a0;
    }
  }
  .selected-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .file-type-name {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      .file-name {
        display: flex;
        align-items: start;
        flex-direction: column;
        width: 100%;
        .file-name-span {
          width: 100%;
          font-family: human_sans_400;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #14283C;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-family: human_sans_400;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            text-align: right;
            color: #91afd7;
          }
        }
        .file-size-span {
          font-family: human_sans_400;
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 12px;
          color: #91afd7;
          width: 100%;
        }
      }
    }
  }
`;

export const StyledProgressBar = styled(Progress)`
  width: 100%;
  margin-bottom: unset;
  :where(.css-dev-only-do-not-override-j0nf2s).ant-progress
    .ant-progress-inner {
    background-color: #c8dcfa;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-progress .ant-progress-text {
    font-family: human_sans_700;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #056dff;
  }
  .ant-progress-bg {
    height: 4px;
  }
`;
