import styled from "styled-components";

export const MyNotificationContainer = styled.div`
  .my-message-box-custom {
    z-index: 1001;
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .message-box-custom-inner {
      padding: 8px;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.12);
      @media (max-width: 657px) {
        width: 345px !important;
      }
      span {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
      }
      .notification-icon {
        @media (max-width: 657px) {
          width: 135px !important;
        }
      }
      .close-notification-div {
        @media (max-width: 657px) {
          margin-right: 10px !important;
        }
        @media (max-width: 400px) {
          margin-right: 20px !important;
        }
        @media (max-width: 300px) {
          margin-right: 25px !important;
        }
      }
    }
  }
`;
