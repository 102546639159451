import React, { useState, useContext } from 'react'
import { HomeWrapper, StyledPhoneInput } from './style'
import Input from '../Input'
import MyDatePicker from '../MyDatePicker'
import "react-phone-number-input/style.css";
import { BsPatchCheckFill, BsPatchExclamationFill } from 'react-icons/bs'
import axios from 'axios';
import moment from 'moment';
import { useLang } from "../../i18n";
import { useIntl } from "react-intl"
import { MyAppContext } from "../../context/MyAppContext";

const Index = () => {
  const {
    setNotification
  } = useContext(MyAppContext);
  const intl = useIntl();
  const lang = useLang();
  const [firstName, setFirstName] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [date, setDate] = useState('')
  const [phone, setPhone] = useState('')
  const [countryCode, setCountryCode] = useState('33')
  const [postalCode, setPostalCode] = useState('')

  // useEffect(() => {
  //   if (phone) {
  //     let phoneNumber = formatPhoneNumber(phone);
  //     if (phoneNumber.length > 0 && phoneNumber.length <= 9) {
  //       setPhone(phone);
  //     }
  //   }
  // }, [countryCode])



  const onSend = () => {
    if (email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
      const formData = new FormData();
      formData.append('first_name', firstName);
      formData.append('full_name', name);
      formData.append('dob', moment(date).format('MM/DD/YYYY'));
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('zipcode', postalCode);

      axios.post(`${process.env.REACT_APP_RECRUITMENT_API_URL}`, formData).then((response) => {
        setNotification({
          message:intl.formatMessage({
            id: "RECRUITMENT_NOTIFICATION",
          }),
          table: ``,
          backGroundColor: `#34CD77`,
          icon: <BsPatchCheckFill color="white" size={20} />,
        });
        setFirstName('')
        setName('')
        setEmail('')
        setDate('')
        setPhone('')
        setCountryCode('33')
        setPostalCode('')
      }).catch((error) => {
        setNotification({
          message: 'Something Went Wrong',
          table: ``,
          backGroundColor: `#DC1E00`,
          icon: <BsPatchExclamationFill color="white" size={20} />,
        });
      });
    } else {
      setNotification({
        message: 'Please enter a valid email',
        table: ``,
        backGroundColor: `#DC1E00`,
        icon: <BsPatchExclamationFill color="white" size={20} />,
      });
    }

  }


  return (
    <HomeWrapper>
      <h1
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "RECRUITMENT.TITLE",
          }),
        }}
      />
      <div className="form-container">
        <Input value={firstName} label={intl.formatMessage({
          id: "MODAL.INPUT.FIRST_NAME",
        })} name={'firstName'} placeholder={intl.formatMessage({
          id: "MODAL.INPUT.FIRST_NAME.PLACEHOLER",
        })} onChange={(e) => setFirstName(e.target.value)} />
        <Input value={name} label={intl.formatMessage({
          id: "MODAL.INPUT.LAST_NAME",
        })} name={'name'} placeholder={intl.formatMessage({
          id: "MODAL.INPUT.LAST_NAME.PLACEHOLER",
        })} onChange={(e) => setName(e.target.value)} />
        <div className="phone-number">
          <label htmlFor="date">{intl.formatMessage({
            id: "MODAL.INPUT.DATE_OF_BIRTH",
          })}</label>
          <MyDatePicker
            value={date}
            className="input-item"
            onChange={(date) => {
              setDate(date);
            }}
            width={'100%'}
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.DATE_OF_BIRTH.PLACEHOLER",
            })}
          />
        </div>
        <div className="phone-number">
          <label htmlFor="phone">{intl.formatMessage({
            id: "MODAL.INPUT.PHONE_NUMBER",
          })}</label>
          <StyledPhoneInput
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.PHONE_NUMBER.PLACEHOLER",
            })}
            international
            value={phone}
            defaultCountry="FR"
            limitMaxLength={true}
            onChange={(value) => {
              // if (value) {
              //   let parsePhoneNumberValue = parsePhoneNumber(value);
              //   setCountryCode(parsePhoneNumberValue?.countryCallingCode);
              // }
              // let phoneNumber = formatPhoneNumber(value);
              setPhone(value);
            }}
            countryCallingCodeEditable={false}
          />
        </div>
        <Input value={email} label={intl.formatMessage({
          id: "MODAL.INPUT.EMAIL",
        })} name={'email'} placeholder={intl.formatMessage({
          id: "MODAL.INPUT.EMAIL.PLACEHOLER",
        })} onChange={(e) => setEmail(e.target.value)} />
        <Input value={postalCode} label={intl.formatMessage({
          id: "MODAL.INPUT.ZIP_CODE",
        })} name={'postalCode'} placeholder={intl.formatMessage({
          id: "MODAL.INPUT.ZIP_CODE.PLACEHOLER",
        })} onChange={(e) => setPostalCode(e.target.value.replace(/[^0-9]/g, ''))} />
        <button className='btn' disabled={!firstName || !name || !email || !date || !phone || !postalCode} onClick={onSend}>{intl.formatMessage({
          id: "JOIN_US",
        })}</button>
      </div>
    </HomeWrapper>
  )
}

export default Index