import React, { useContext, useEffect } from "react";
import { LayoutPageContainer } from "./style";
import NavBar from "./NavBar/NavBar";
import Footer from "./Footer/Footer";
import { MyAppContext } from "../context/MyAppContext";
import PreSubscribeForm from "../components/PreSubscribeForm";
import MyNotification from "../components/Elements/MyNotification";
import CancelConfirmationModal from "../components/PreSubscribeForm/CancelConfirmationModal";
import BecomeResponderForm from "../components/BecomeResponderForm";
import CancelBecomeResponderConfirmationModal from "../components/BecomeResponderForm/CancelBecomeResponderConfirmationModal";
import axios from "axios";

const Layout = ({ children }) => {
  const {
    isPreSubscribeModalOpen,
    notification,
    setNotification,
    cancelConfiramtionPreSubscribeModal,
    isBecomeResponderModalOpen,
    cancelBecomeResponderForm,
    setUserLoginGeoLocation,
  } = useContext(MyAppContext);

  // useEffect(() => {
  //   if (notification) {
  //     setTimeout(() => setNotification(null), 2 * 1000);
  //   }
  // }, [notification]);

  useEffect(() => {
    axios
      .get(`https://ipapi.co/json/`)
      .then((res) => {
        if (res.status === 200) {
          setUserLoginGeoLocation(res.data);
        }
      })
      .catch(() => {});
  }, []);

  return (
    // <LayoutContainer width={isMobile}>
    //   {notification && (
    //     <MyNotification
    //       message={notification?.message ? notification.message : ""}
    //       table={notification?.table ? notification.table : ""}
    //       backGroundColor={
    //         notification?.backGroundColor ? notification.backGroundColor : ""
    //       }
    //       icon={notification?.icon ? notification.icon : ""}
    //       onClick={() => setNotification(null)}
    //     />
    //   )}

    //   <NavBar />
    //   {location.pathname === "/become-responder" ? (
    //     <div className="becomeresponder-child-div">
    //       {children}
    //       <Footer />
    //     </div>
    //   ) : (
    //     <div
    //       style={{
    //         width: isMobile ? "fit-content" : "auto",
    //       }}
    //     >
    //       {children}
    //       <Footer />
    //     </div>
    //   )}

    //   {isPreSubscribeModalOpen && <PreSubscribeForm />}
    //   {cancelConfiramtionPreSubscribeModal && <CancelConfirmationModal />}
    //   {isBecomeResponderModalOpen && <BecomeResponderForm />}
    //   {cancelBecomeResponderForm && <CancelBecomeResponderConfirmationModal />}
    // </LayoutContainer>
    <LayoutPageContainer
      isModalOpen={
        isPreSubscribeModalOpen ||
        cancelConfiramtionPreSubscribeModal ||
        isBecomeResponderModalOpen ||
        cancelBecomeResponderForm
          ? true
          : false
      }
    >
      {notification && (
        <MyNotification
          message={notification?.message ? notification.message : ""}
          table={notification?.table ? notification.table : ""}
          backGroundColor={
            notification?.backGroundColor ? notification.backGroundColor : ""
          }
          icon={notification?.icon ? notification.icon : ""}
          onClick={() => setNotification(null)}
        />
      )}
      <NavBar />
      {children}
      <Footer />
      {isPreSubscribeModalOpen && <PreSubscribeForm />}
      {cancelConfiramtionPreSubscribeModal && <CancelConfirmationModal />}
      {isBecomeResponderModalOpen && <BecomeResponderForm />}
      {cancelBecomeResponderForm && <CancelBecomeResponderConfirmationModal />}
    </LayoutPageContainer>
  );
};

export default Layout;
