import React from "react";
import {
  FooterContainer,
  FooterTopContent,
  FooterBottomContent,
} from "./style";
import {
  LinkedInLogo,
  FaceBookLogo,
  InstagramLogo,
  TwitterLogo,
  WPTFDarkLogo,
  MobileIcon,
  MapIcon,
  EmailIcon,
  GooglePlayIcon,
  AppStoreIcon,
} from "../../assests";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <FooterTopContent>
        <div className="footer-logo">
          <img src={WPTFDarkLogo} alt="" />
        </div>
        <div className="footer-pages">
          <div
            className="footer-pages-header"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.PAGES",
              }),
            }}
          />
          <div
            className="footer-pages-name"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.ABOUT_US",
              }),
            }}
            onClick={() => {
              navigate("/about-us");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          <div
            className="footer-pages-name"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.BECOME_RESPONDER",
              }),
            }}
            onClick={() => {
              navigate("/become-responder");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          <div
            onClick={() => {
              navigate("/legal-pages");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="footer-pages-name"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.LEGAL_NOTICE",
              }),
            }}
          />
          <div
            onClick={() => {
              navigate("/privacy-policy");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="footer-pages-name"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.PRIVACY_POLICY",
              }),
            }}
          />
        </div>
        <div className="footer-contants">
          <div
            className="footer-contants-header"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "FOOTER.CONTACTS",
              }),
            }}
          />
          <div className="footer-contants-name">
            <img src={MobileIcon} alt="" />0 801 902 380
          </div>
          <div className="footer-contants-name">
            <img src={MapIcon} alt="" />
            {intl.formatMessage({
              id: "FOOTER.ADDRESS",
            })}
          </div>
          <div className="footer-contants-name">
            <img src={EmailIcon} alt="" />
            weprotectsupport@wp.com
          </div>
        </div>
        <div className="footer-client">
          <div className="client-app">
            <div
              className="meta"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "FOOTER.CLIENT_APP",
                }),
              }}
            />
            <div className="client-app-logos">
              <img src={GooglePlayIcon} alt="" />
              <img src={AppStoreIcon} alt="" />
            </div>
          </div>
          <div className="client-app">
            <div
              className="meta"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "FOOTER.RESPONDER_APP",
                }),
              }}
            />
            <div className="client-app-logos">
              <img src={GooglePlayIcon} alt="" />
              <img src={AppStoreIcon} alt="" />
            </div>
          </div>
        </div>
      </FooterTopContent>
      <FooterBottomContent>
        <div className="brand-icon">
          <span>
            <img src={LinkedInLogo} alt="" />
          </span>
          <span>
            <img src={InstagramLogo} alt="" />
          </span>
          <span>
            <img src={FaceBookLogo} alt="" />
          </span>
          <span>
            <img src={TwitterLogo} alt="" />
          </span>
        </div>
        <div className="brand-brand-name">
          © {new Date().getFullYear()} &nbsp;
          {intl.formatMessage({
            id: "FOOTER.ALL_RIGHTS_RESERVED",
          })}
        </div>
      </FooterBottomContent>
    </FooterContainer>
  );
};

export default Footer;
