import React from "react";
import { MyDatePickerContainer, StyledDatePicker } from "./style";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from "react-dom";

const MyDatePicker = ({
  label = "",
  value = "",
  onChange = () => {},
  className = "",
  width = "",
  placeholder = "",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <MyDatePickerContainer width={width} value={value !== ""}>
      {label && <label>{label}</label>}
      <StyledDatePicker
        value={value}
        onChange={(date) => onChange(date)}
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        className={className}
        format="MM/dd/yyyy"
        placeholderText={placeholder}
        maxDate={new Date()}
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        yearPlaceholder="YYYY"
        showLeadingZeros={true}
        closeCalendar={false}
        isOpen={isOpen}
      ></StyledDatePicker>
      {isOpen &&
        createPortal(
          <div className="date-buttons">
            <button
              className="cancel-calender-btn"
              onClick={() => {
                onChange("");
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="apply-calender-btn"
              style={{
                opacity: value !== "" ? "1" : "0.5",
              }}
              disabled={value === ""}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Apply
            </button>
          </div>,
          document.querySelector(".react-calendar")
        )}
    </MyDatePickerContainer>
  );
};

export default MyDatePicker;
