import styled from "styled-components";

export const AboutUsContainer = styled.div`
  width: -webkit-fill-available;
  .keeping-safe-container {
    padding-top: 134px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 476px) {
      padding: ${(props) =>
        props.isFrenchLangActive ? "0px 0px 100px 0" : "0px 0px 50px 0"};
    }
    @media (max-width: 900px) {
      padding: ${(props) =>
        props.isFrenchLangActive ? "0px 0px 100px 0" : "0px 0px 50px 0"};
    }
    @media (max-width: 376px) {
      padding: ${(props) =>
        props.isFrenchLangActive ? "0px 0px 150px 0" : "0px 0px 50px 0"};
    }
    @media (max-width: 358px) {
      padding: ${(props) =>
        props.isFrenchLangActive ? "0px 0px 200px 0" : "0px 0px 50px 0"};
    }
    .inner-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: ${(props) => (props.isFrenchLangActive ? "4rem" : "unset")};
      @media (max-width: 300px) {
        padding-right: 77px;
      }
      @media (max-width: 396px) {
        margin-top: 15rem !important;
      }
      @media (max-width: 492px) {
        margin-top: ${(props) =>
          props.isMobile ? "15rem !important" : "10rem !important"};
      }
      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 72px;
        text-align: center;
        letter-spacing: 0.024em;
        text-transform: uppercase;
        color: #ffffff;
        @media (max-width: 1000px) {
          font-size: 40px;
          line-height: 42px;
        }
        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 42px;
        }
      }
      .sub-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 50px;
        text-align: center;
        letter-spacing: 0.024em;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 32px;
        margin-bottom: 32px;
        @media (max-width: 1000px) {
          line-height: unset;
        }
      }
      .action-button {
        width: 316px;
        height: 62px;
        // margin-bottom: 32px;
      }
      .app-store-icons {
        margin-top: ${(props) => (props.isFrenchLangActive ? "60px" : "120px")};
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 396px) {
          margin-top: 60px;
        }
        @media (max-width: 492px) {
          margin-top: 40px !important;
        }
        img {
          height: 40px;
          width: 135px;
          border-radius: 5px;
        }
      }
    }
  }
`;

export const YourPreferencesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 120px 160px 140px 160px;
  width: -webkit-fill-available;
  @media (max-width: 768px) {
    padding: 50px 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }
  @media (max-width: 1000px) {
    padding: 50px 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }
  .working-points {
    flex: 1 1 0px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 1000px) {
      text-align: center;
    }
    .header-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #056dff;
      margin-bottom: 24px;
      span {
        color: #14283c;
      }
    }
    ul {
      li {
        padding-left: 5px;
        font-family: human_sans_400;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.004em;
        color: #375a87;
        margin-top: 20px;
        list-style-image: ${(props) =>
          props.listImage && `url(${props.listImage})`};
        ::marker {
          margin-top: 10px !important;
        }
        span {
          font-family: human_sans_700;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.004em;
          color: #375a87;
        }
        @media (max-width: 768px) {
          text-align: start;
        }
        @media (max-width: 1000px) {
          text-align: start;
        }
      }
    }
  }
  .wroking-image {
    flex: 1 1 0px;
    position: relative;

    img {
      height: 530px;
      width: 477px;
      @media (max-width: 768px) {
        height: 330px;
        width: 277px;
      }
      @media (max-width: 1000px) {
        height: 330px;
        width: 277px;
      }
    }
  }
  .your-preference-action-button {
    height: 50px;
    width: 248px;
    margin-top: 30px;
  }
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0 160px 140px 160px;
  @media (max-width: 768px) {
    padding: 50px 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }
  @media (max-width: 1000px) {
    padding: 50px 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }

  .working-points {
    flex: 1 1 0px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 1000px) {
      text-align: center;
    }
    .header-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #056dff;
      margin-bottom: 24px;
      span {
        color: #14283c;
      }
    }
    ul {
      li {
        padding-left: 5px;
        font-family: human_sans_400;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.004em;
        color: #375a87;
        margin-top: 20px;
        list-style-image: ${(props) =>
          props.listImage && `url(${props.listImage})`};
        ::marker {
          margin-top: 10px !important;
        }
        span {
          font-family: human_sans_700;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.004em;
          color: #375a87;
        }
        @media (max-width: 768px) {
          text-align: start;
        }
        @media (max-width: 1000px) {
          text-align: start;
        }
      }
    }
  }
  .wroking-image {
    flex: 1 1 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;

    img {
      height: 548px;
      width: 477px;
      @media (max-width: 768px) {
        height: 348px;
        width: 277px;
      }
      @media (max-width: 1000px) {
        height: 348px;
        width: 277px;
      }
    }

    .sos-iamge {
      position: absolute;
      height: 121px;
      width: 93px;
      left: 16rem;
      top: 16rem;
    }
  }
  .how-works-action-button {
    height: 50px;
    margin-top: 24px;
    height: 50px;
    width: 400px;
    @media (max-width: 600px) {
      height: fit-content;
      width: 350px;
      padding: 5px 0;
    }
  }
`;

export const ProtectionPlanContainer = styled.div`
  // height: 850px;
  position: relative;
  padding-bottom: 5rem;
  transform: skewY(177deg);
  background: linear-gradient(0deg, white, #a8cbe5 324%);
  position: relative;
  z-index: 1;
  margin-top: -70px;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 1000px) {
    height: auto;
  }
  .inner-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: skewY(2.9deg);
    margin: 20px;
    .div-header {
      font-family: Roboto;
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #14283c;
      margin-top: 120px;
      span {
        color: #056dff;
      }
    }
    .div-plans {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 41px;
      gap: 41px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      @media (max-width: 1000px) {
        flex-direction: column;
      }
      .plan {
        width: 364px;
        border-radius: 30px;
        background: #ffffff;
        backdrop-filter: blur(12px);
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .plan-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 42px;
          .header-left {
            font-family: human_sans_700;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #056dff;
          }
          .header-right {
            font-family: human_sans_700_bold;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 24px;
            text-align: right;
            letter-spacing: 0.4px;
            color: #5573a0;
            span {
              font-family: human_sans_400;
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.4000000059604645px;
              text-align: right;
            }
          }
        }
        .plan-list {
          .plan-list-item {
            display: flex;
            align-items: start;
            gap: 12px;
            font-family: human_sans_400_bold;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.177778px;
            color: #14283c;
            margin-bottom: 8px;
            b {
              font-family: human_sans_700;
              font-weight: 700;
            }
            .fa-circle {
              font-style: normal;
              font-weight: 900;
              font-size: 6px;
              line-height: 24px;
              letter-spacing: 0.177778px;
              color: #14283c;
            }
          }
          .plan-star {
            display: flex;
            align-items: start;
            gap: 12px;
            font-family: human_sans_400;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #14283c;
            opacity: 0.7;
            margin-top: 16px;
          }
        }
        .subscription-action {
          .subscription-div {
            background-color: #ffa53326;
            border-radius: 30px;
            padding: 12px;
            display: flex;
            align-items: start;
            gap: 12px;
            margin-bottom: 42px;
            .subscription-info {
              font-family: human_sans_400;
              font-weight: 400;
              font-style: normal;
              font-size: 16px;
              line-height: 20px;
              color: #14283c;
            }
          }
          .subscription-action-button {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const ProjectRemoteContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0 160px 120px 160px;
  height: auto;
  @media (max-width: 768px) {
    padding: 0 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }
  @media (max-width: 1000px) {
    padding: 0 50px 50px 50px;
    flex-direction: column;
    justify-content: start;
  }
  .working-points {
    flex: 1 1 0px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 1000px) {
      text-align: center;
    }
    .header-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #056dff;
      margin-bottom: 24px;
      span {
        color: #14283c;
      }
    }
    ul {
      li {
        padding-left: 5px;
        font-family: human_sans_400;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.004em;
        color: #375a87;
        margin-top: 20px;
        list-style-image: ${(props) =>
          props.listImage && `url(${props.listImage})`};
        ::marker {
          margin-top: 10px !important;
        }
        span {
          font-family: human_sans_700;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.004em;
          color: #375a87;
        }
        @media (max-width: 768px) {
          text-align: start;
        }
        @media (max-width: 1000px) {
          text-align: start;
        }
      }
    }
  }
  .image-div {
    flex: 1 1 0px;

    position: relative;
    img {
      height: 514px;
      width: 477px;
      @media (max-width: 768px) {
        height: 314px;
        width: 277px;
      }
      @media (max-width: 1000px) {
        height: 314px;
        width: 277px;
      }
    }
  }
  .action-button {
    width: 248px;
    height: 50px;
    margin-top: 30px;
  }
`;

export const WorkRemoteContainer = styled.div`
  position: relative;
  z-index: 10;
`;

export const JobsContainer = styled.div`
  padding: 120px 160px 120px 160px;
  @media (max-width: 300px) {
    padding: 50px 160px 50px 160px;
  }
  @media (max-width: 410px) {
    padding: 50px 160px 50px 160px;
  }
  @media (max-width: 415px) {
    padding: 50px 160px 50px 160px;
  }
  position: relative;
  z-index: 0;
  height: 818px;
  margin-top: -60px;
  background-image: ${(props) =>
    props.image
      ? `linear-gradient(0deg, rgba(5, 109, 255, 0.16), rgba(5, 109, 255, 0.16)), linear-gradient(186.05deg, rgba(70, 86, 149, 0.2) 4.79%, rgba(15, 27, 77, 0.2) 91.43%),url(${props.image})`
      : ""};
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 133px;
  .header-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .header-subtitle1 {
    font-family: roboto_condensed_700;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 12px;
    @media (max-width: 1000px) {
      width:400px;
    }
    @media (max-width: 768px) {
      width:400px;
    }
    @media (max-width: 410px) {
      width:400px;
    }
    @media (max-width: 415px) {
      width:400px;
    }
  }
  .header-subtitle2 {
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.004em;
    color: #ffffff;
    margin-bottom: 32px;
    @media (max-width: 1000px) {
      width:400px;
    }
    @media (max-width: 768px) {
      width:400px;
    }
    @media (max-width: 410px) {
      width:400px;
    }
    @media (max-width: 415px) {
      width:400px;
    }
  }
  .jobs-action-button {
    height: 50px;
    width: max-content;
    @media (max-width: 600px) {
      height: fit-content;
      width:350px;
      padding:5px 0;
    }
  }
`;
