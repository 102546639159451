import styled from "styled-components";

export const MyCancelButtonContainer = styled.button`
  height: 50px;
  border-radius: 12px;
  padding: 0px 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  border-color: transparent !important;

  font-family: "human_sans_700";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ff2c2c;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
`;
