import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  z-index: 10;
`;

export const FooterTopContent = styled.div`
  padding: 64px 160px;
  background-color: #ffffff;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap:50px;
  @media (max-width: 768px) {
    padding: 64px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 64px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .footer-logo {
    img {
      height: 31px;
      width: 127px;
    }
  }
  .footer-pages {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 1000px) {
      align-items: center;
    }
    gap: 16px;
    .footer-pages-header {
      font-family: "human_sans_400";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #375a87;
    }
    .footer-pages-name {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: start;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #14283c;
      cursor: pointer;
    }
  }
  .footer-contants {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 1000px) {
      align-items: center;
    }
    .footer-contants-header {
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #375a87;
    }
    .footer-contants-name {
      display: flex;
      align-items: center;
      justify-content: start;
      font-family: human_sans_400;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #14283c;
      gap: 10px;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
  .footer-client {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap: 28px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 1000px) {
      text-align: center;
    }
    .client-app {
      .meta {
        font-family: "human_sans_400";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #375a87;
        span {
          font-family: "human_sans_700_bold";
        }
        margin-bottom: 12px;
      }
      .client-app-logos {
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          height: 40px;
          width: 135px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const FooterBottomContent = styled.div`
  padding: 24px 160px;
  background-color: #056dff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 24px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 24px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .brand-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    span {
      img {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.004em;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .brand-brand-name {
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.004em;
    color: #c8dcfa;
    text-align: center;
  }
`;
