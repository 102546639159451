import styled from "styled-components";
import { Button } from "react-bootstrap";

export const MyButtonContainer = styled(Button)`
  height: 50px;
  border-radius: 12px;
  padding: 0px 30px;
  background-color: ${(props) =>
    props.type === "black" ? "#14283C !important" : "#056dff !important"};
  border-color: transparent !important;

  font-family: human_sans_700_bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.2" : "1")};
`;
