import React from "react";
import { MyNotificationContainer } from "./style";

const MyNotification = ({ message, onClick, backGroundColor, icon }) => {
  return (
    <MyNotificationContainer>
      <div className="my-message-box-custom" style={{}}>
        <div
          className="message-box-custom-inner"
          style={{
            background:
              "linear-gradient(0deg, rgba(52, 205, 119, 0.1), rgba(52, 205, 119, 0.1)), #FFFFFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "auto",
          }}
        >
          <div
            style={{
              marginRight: "12px",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: backGroundColor,
              borderRadius: "50%",
            }}
            className="notification-icon"
          >
            {icon}
          </div>
          <div>
            <h5
              style={{
                fontFamily: "human_sans_400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#14283C",
                margin: " unset",
              }}
              dangerouslySetInnerHTML={{ __html: message }}
            ></h5>
          </div>
          <button
            style={{
              marginLeft: "30px",
              marginRight: "24px",
              width: "11px",
              // height: "24px",
              background: "transparent",
              border: "none",
              color: "#14283C",
              opacity: "0.5",
              fontWeight: "600",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={onClick}
            className="close-notification-div"
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </MyNotificationContainer>
  );
};

export default MyNotification;
