import styled from "styled-components";

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    label{
        font-size: 11px;
        font-family: human_sans_400;
        line-height: 12px;
    }

    input{
        height: 40px;
        background-color: #fcfcfc;
        border-radius: 12px;
        padding: 10px 12px;
        font-size: 14px;
        font-family: human_sans_400;
        font-weight: 400;
        line-height: 20px;
        color:#14283C;
        outline: transparent;
        border: 1px solid #fff;

        &::placeholder{
            color:#91AFD7;
        }
    }
`;

