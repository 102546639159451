import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { MyAppContextContainer } from "../context/MyAppContext";
import AboutUsPage from "../components/AboutUs";
import BecomeResponderPage from "../components/BecomeResponder";
import PageNotFoundPage from "../components/PageNotFound";
import PrivacyPolicy from "../components/Privacy Policy";
import LegalPages from "../components/Legal Pages";
import RecruitmentPage from "../components/RecruitmentPage";
export function MyRoutes() {
  return (
    <MyAppContextContainer>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Navigate to="/about-us" />} />
          <Route exact path="/about-us" element={<AboutUsPage />} />
          <Route
            exact
            path="/become-responder"
            element={<BecomeResponderPage />}
          />
           <Route
            exact
            path="/recruiting"
            element={<RecruitmentPage />}
          />
          <Route path="*" exact={true} element={<PageNotFoundPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal-pages" element={<LegalPages />} />
        </Routes>
      </Layout>
    </MyAppContextContainer>
  );
}
