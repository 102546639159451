import styled from "styled-components";

export const ArticlesContainer = styled.div`
  .page-not-found-container {
    padding-top: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;

    /* justify-content: space-around; */
    .inner-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 10;

      .title {
        font-family: "human_sans_700_bold";
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .articles-container {
    padding: 40px 160px 60px 160px;
    background-color: #f7fafe;

    @media screen and (max-width: 1000px) {
      padding: 40px;
    }
    h2 {
      font-family: "human_sans_700_bold";
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: #08112e;
      margin-bottom: 10px;
    }

    .date {
      color: #7d8ca5;
      font-family: human_sans;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      font-family: "human_sans_700";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #373c55;
    }

    h3 {
      font-family: "human_sans_700_bold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #08112e;
    }

    ul {
      li {
        font-family: "human_sans_700";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
`;
