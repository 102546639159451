import React, { useState, createContext } from "react";

const MyAppContext = createContext({});

export { MyAppContext };

export const MyAppContextContainer = (props) => {
  const [myAccount, setMyAccount] = useState({});
  const [isPreSubscribeModalOpen, setIsPreSubscribeModalOpen] = useState(false);
  const [
    cancelConfiramtionPreSubscribeModal,
    setCancelConfirmationPreSubscribeModal,
  ] = useState(false);
  const openPreSubscribeModal = () => {
    setIsPreSubscribeModalOpen(!isPreSubscribeModalOpen);
  };
  const closePreSubscribeModal = () => {
    // setIsPreSubscribeModalOpen(false);
    setCancelConfirmationPreSubscribeModal(true);
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const [notification, setNotification] = useState(null);

  const [isBecomeResponderModalOpen, setIsBecomeResponderModalOpen] =
    useState(false);

  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [
    isTransportationTypeDropDownOpen,
    setIsTransportationTypeDropDownOpen,
  ] = useState(false);

  const [country, setCountry] = useState("France");
  const [state, setState] = useState("");

  const [passportId, setPassportId] = useState("");
  const [selectedPassportFile, setSelectedPassportFile] = useState("");

  const [ssn, setSSN] = useState("");
  const [selectedSSNFile, setSelectedSSnFile] = useState("");

  const [document, setDocument] = useState("");
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");

  const [cancelBecomeResponderForm, setCancelBecomeResponderForm] =
    useState(false);

  const [countryCode, setCountryCode] = useState("");

  const [userLoginGeoLocation, setUserLoginGeoLocation] = useState("");

  return (
    <MyAppContext.Provider
      value={{
        myAccount,
        setMyAccount,
        isPreSubscribeModalOpen,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phone,
        setPhone,
        zipCode,
        setZipCode,
        dateOfBirth,
        setDateOfBirth,
        gender,
        setGender,

        notification,
        setNotification,

        cancelConfiramtionPreSubscribeModal,
        setCancelConfirmationPreSubscribeModal,

        openPreSubscribeModal,
        closePreSubscribeModal,

        isBecomeResponderModalOpen,
        setIsBecomeResponderModalOpen,

        selectedTransactionType,
        setSelectedTransactionType,
        isTransportationTypeDropDownOpen,
        setIsTransportationTypeDropDownOpen,

        address,
        setAddress,
        city,
        setCity,

        country,
        setCountry,
        state,
        setState,

        passportId,
        setPassportId,
        selectedPassportFile,
        setSelectedPassportFile,

        ssn,
        setSSN,
        selectedSSNFile,
        setSelectedSSnFile,

        document,
        setDocument,
        selectedDocumentFile,
        setSelectedDocumentFile,

        cancelBecomeResponderForm,
        setCancelBecomeResponderForm,
        setIsPreSubscribeModalOpen,

        countryCode,
        setCountryCode,

        userLoginGeoLocation,
        setUserLoginGeoLocation,
      }}
    >
      {props.children}
    </MyAppContext.Provider>
  );
};
