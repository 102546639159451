import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes/Routes";
import { I18nProvider } from "./i18n";

const App = () => {
  return (
    <BrowserRouter>
      <I18nProvider>
        <MyRoutes />
      </I18nProvider>
    </BrowserRouter>
  );
};

export default App;
